<template>
    <div class="bo--container">
        <template>
            <div class="container">
                <loader :loader="loading"/>
                <bo-card>
                    <template slot="title">
                        <h1 class="bo-card--title" v-if="userCanManageUsers">
                            <a :href="`${routeNameReact}/new/back-office/liste-personnel`">&lt; </a> {{isEdit ? "Modifier l'utilisateur" : "Créer un utilisateur"}}
                        </h1>
                        <h1 class="bo-card--title" v-else>
                            <a href="#" @click.prevent="cancel">&lt; </a> Voir l'utilisateur
                        </h1>
                    </template>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <form-select v-model="structure"
                                         :options="structureListSelect"
                                         label="Structure*"
                                         :error="!$v.structure.required && $v.structure.$error ? 'La structure est obligatoire.' : null"
                                         :login-input="true"
                                         :disabled="!userCanManageUsers">
                                <template v-slot:first>
                                    <option value="" selected disabled> Selectionnez une structure</option>
                                </template>
                            </form-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="email"
                                                type="email"
                                                label="Email*"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.email.required && $v.email.$error ? `L'email est obligatoire.` : null"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="lastName"
                                                label="Nom*"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.lastName.required && $v.lastName.$error ? `Le nom est obligatoire.` : null"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="firstName"
                                                label="Prénom*"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.firstName.required && $v.firstName.$error ? `Le prénom est obligatoire.` : null"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="birthName"
                                                label="Nom de naissance"
                                                :login-input="true"
                                                :disable="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                  <FormDatePicker v-model="birthDate"
                                                label="Date de naissance*"
                                                :value ="birthDate"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.birthDate.required && $v.birthDate.$error ? `La date de naissance est obligatoire.` : null">
                                  </FormDatePicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="address"
                                                label="Adresse*"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.address.required && $v.address.$error ? `L'adresse est obligatoire.` : null"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="addressComplement"
                                                label="Complément d'adresse"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="city"
                                                label="Ville*"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.city.required && $v.city.$error ? `La ville est obligatoire.` : null"/>
                                </div>
                            </div>
                        </div>
                      <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="cp"
                                                label="Code postal*"
                                                type="number"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.cp.required && $v.cp.$error ? `Le code postal est obligatoire.` : null"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-3 col-md-3">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="portablePhoneNumber"
                                                label="Téléphone portable personnel"
                                                type="number"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="portableProfesionnalPhoneNumber"
                                                label="Téléphone portable professionnel*"
                                                type="number"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"
                                                :error="!$v.portableProfesionnalPhoneNumber.required && $v.portableProfesionnalPhoneNumber.$error ? `Le téléphone portable professionel est obligatoire.` : null"/>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="homePhoneNumber"
                                                label="Téléphone fixe domicile"
                                                type="number"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="homeProfesionnalPhoneNumber"
                                                label="Téléphone fixe travail"
                                                type="number"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="effectiveDate"
                                                label="Date de dernière synchronisation"
                                                :value="effectiveDate"
                                                :login-input="true"
                                                :disabled="true"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="type"
                                                label="Type de personnel"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="profesionnalEmail"
                                                type="email"
                                                label="Mail professionnel"
                                                :login-input="true"
                                                :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-14 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <form-input v-model="manager" label="Responsable hiérarchique"
                                                :value="manager ? manager.firstName + ' ' + manager.lastName : ''"
                                                :login-input="true" :disabled="!userCanManageUsers"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4 col-md-4">
                            <form-select v-model="intervenantType"
                                         :options="intervenantTypeList"
                                         label="Le type d'intervenant *"
                                         :error="!$v.intervenantType.required && $v.intervenantType.$error ? 'Le type d\'intervenant est obligatoire.' : null"
                                         :login-input="true"
                                         :disabled="!userCanManageUsers">
                                <template v-slot:first>
                                    <option value="" selected disabled>Selectionnez un type d'intervenant</option>
                                </template>
                            </form-select>
                        </div>

                        <div class="col-sm-4 col-md-4">
                            <form-select v-model="roleId"
                                         :options="roleListSelect"
                                         label="Profil *"
                                         :error="!$v.roleId.required && $v.roleId.$error ? 'Le profil est obligatoire.' : null"
                                         :login-input="true"
                                         :disabled="!userCanManageUsers">
                                <template v-slot:first>
                                    <option value="" selected disabled>Selectionnez un profil</option>
                                </template>
                            </form-select>
                        </div>

                        <div class="col-sm-4 col-md-4" v-if="!isEditingPassword && isEdit && userCanManageUsers">
                            <div class="col-12">
                                <button class="btn btn-primary" @click.prevent="editPassword">
                                    Modifier le mot de passe
                                </button>
                            </div>
                        </div>

                        <div class="col-sm-4 col-md-4" v-if="isEditingPassword && userCanManageUsers">
                            <div class="col-12">
                                <form-input :label="hasPassword ? '*****' : 'Nouveau mot de passe'" type="password" v-model="password" :login-input="true" @input="clearPasswordError"
                                            :error="passwordError.passwordEmpty || passwordError.confirmPasswordError ? 'le mot de passe est obligatoire' : null"/>
                            </div>
                            <div class="col-12">
                                <form-input type="password" v-model="confirmPassword" :label="hasPassword ? '*****' : 'Confirmation nouveau mot de passe'"
                                            :login-input="true" @input="clearConfirmPasswordError"
                                            :error="passwordError.confirmPasswordEmpty || passwordError.confirmPasswordError ? 'les 2 mots de passe ne sont pas égaux' : null"/>
                            </div>
                            <div class="row">
                                <div class="col-lg-5 col-md-12">
                                    <button class="btn btn-primary pl-3 pr-3" @click.prevent="submitPassword"
                                            v-if="isEditingPassword" :disabled="passwordSaveLoading">
                                        <font-awesome-icon v-show="passwordSaveLoading" :icon="['fas', 'spinner']" spin/>
                                        Enregistrer
                                    </button>
                                </div>
                                <div class="col-lg-5 col-md-12">
                                    <button class="btn btn-outline-primary pl-3 pr-3" @click.prevent="cancelEditPassword">
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                      <GridLight
                          label="Liste des agences"
                          :columns="userAgenciesColumns"
                          :dataList="userAgencies"
                          :loading="loading"
                          :assignable="isUserRoleOperationManager || isUserRoleAreaManager"
                          :canDelete="isUserRoleOperationManager || isUserRoleAreaManager"
                          @clickAssign="showAssignAgency"
                          @confirmRemove="removeAgency"
                      />
                    </div>
                    <div class="row mt-5">
                        <div class="col-sm-3">
                            <form-toggle append="Actif" prepend="Inactif" v-model="status" name="toggle" :disabled="!userCanManageUsers"/>
                        </div>
                    </div>
                    <template slot="footer">
                        <button v-if="userCanManageUsers" class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading">
                            <font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/>
                            Enregistrer
                        </button>
                        <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
                    </template>
                </bo-card>
            </div>
        </template>
        <b-modal id="assign_agency" :centered="true" size="lg">
          <template v-slot:modal-title>
            Ajouter une agence à l'utilisateur
          </template>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <form-autocomplete v-model="newAgencyId"
                                   :list="agencyList"
                                   label="Liste des agences *"
                                   :error="!newAgencyId && isSubmitAssign ? 'l\'agence est obligatoire' : null"
                                   :login-input="true"
                                   :multiple="true"
                                   @onInput="onInputUser"
                                   @onSelect="onSelect"
                                   />
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="isChecked"
                           @change="updateAgencyList">Sélectionner toutes les agences
                  </label>
                </div>
              </div>
            </div>
          </div>
          <template v-slot:modal-footer>
            <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-primary" @click.prevent="submitAssignAgency">Ajouter agence</b-button>
            <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="cancelAssignAgency">Annuler</b-button>
          </template>
        </b-modal>
    </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {required} from 'vuelidate/lib/validators';
import FormInput from "@/components/Form/Input";
import FormToggle from "@/components/Form/Toggle";
import FormDatePicker from "@/components/Form/DatePicker";
import GridLight from "@/components/Grid/GridLight";
import FormAutocomplete from "@/components/Form/Autocomplete";

export default {
        name: "BOUserForm",
        components: {
            FormInput,
            FormToggle,
            FormDatePicker,
            GridLight,
            FormAutocomplete,
        },
        data() {
            return {
                bo: {
                    active: false
                },
                showTable: true,
                intervenantTypeList: [
                    {value: 'Médical', text: 'Médical'},
                    {value: 'Aidant professionnel', text: 'Intervenant'},
                    {value: 'Administratif', text: 'Administratif'},
                ],
                isEditingPassword: false,
                password:'',
                confirmPassword: '',
                passwordError: {
                    passwordEmpty: false,
                    confirmPasswordEmpty: false,
                    confirmPasswordError: false,
                },
                userAgenciesColumns: [
                  {name: "Nom de l'agence", value: 'companyName'},
                ],
                newAgencyId: [],
                isSubmitAssign: false,
                selectedAgencies: [],
                isChecked: false
            };
        },
        validations() {
            return  {
                structure: {
                  required
                },
                email: {
                  required
                },
                lastName: {
                  required
                },
                firstName: {
                  required
                },
                birthDate: {
                  required
                },
                address: {
                  required
                },
                city: {
                  required
                },
                cp:{
                  required
                },
                portableProfesionnalPhoneNumber:{
                  required
                },
                roleId: {
                    required
                },
                intervenantType: {
                    required
                }
            };
        },
        computed: {
            ...mapState('User', ['user', 'error', 'loading', 'saveLoading', 'roleList', 'passwordSaveLoading', 'connectedUser']),
            ...mapState('Structure', ['structures']),
            ...mapState('Agency', ['agencies']),
            ...mapGetters("User", ["isUserRoleOperationManager", "isUserRoleAreaManager"]),
            userCanManageUsers() {
              let storedPermissions = sessionStorage.getItem('permissions');
              let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

              return permissionsArray.includes('CAN_UPDATE_USER');
            },
            hasPassword() {
                return this.user.hasPassword;
            },
            routeNameReact() {
              const protocol = window.location.protocol;
              const domain = window.location.hostname;
              const port = window.location.port;
              const fullDomain = port ? `${protocol}//${domain}:3001` : `${protocol}//${domain}`;
              return fullDomain;
            },
            structure: {
                get() {
                    return this.user.structureId
                },
                set(value) {
                  this.setUserProperty({
                    name: 'structureId',
                    value: value
                  })
                }
            },
            email: {
                get() {
                    return this.user.email
                },
                set(value) {
                  this.setUserProperty({
                    name: 'email',
                    value: value
                  })
                }
            },
            lastName: {
                get() {
                    return this.user.lastName
                },
                set(value) {
                  this.setUserProperty({
                    name: 'lastName',
                    value: value
                  })
                }
            },
            firstName: {
                get() {
                    return this.user.firstName
                },
                set(value) {
                  this.setUserProperty({
                    name: 'firstName',
                    value: value
                  })
                }
            },
            birthName: {
                get() {
                    return this.user.birthName
                },
                set(value) {
                  this.setUserProperty({
                    name: 'birthName',
                    value: value
                  })
                }
            },
            birthDate: {
                get() {
                  return this.user.birthDate ? this.user.birthDate : null
                },
                set(value) {
                  value.setHours(2)
                  this.setUserProperty({
                    name: 'birthDate',
                    value: value
                  })
                }
            },
            effectiveDate: {
                get() {
                  return this.user.effectiveDate ? this.user.effectiveDate : null
                },
                set(value) {
                  this.setUserProperty({
                    name: 'effectiveDate',
                    value: value
                  })
                }
            },
            type: {
                get() {
                    return this.user.type
                },
                set(value) {
                  this.setUserProperty({
                    name: 'type',
                    value: value
                  })
                }
            },
            address: {
                get() {
                    return this.user.address
                },
                set(value) {
                  this.setUserProperty({
                    name: 'address',
                    value: value
                  })
                }
            },
            addressComplement: {
                get() {
                    return this.user.addressComplement
                },
                set(value) {
                  this.setUserProperty({
                    name: 'addressComplement',
                    value: value
                  })
                }
            },
            city: {
                get() {
                    return this.user.city
                },
                set(value) {
                  this.setUserProperty({
                    name: 'city',
                    value: value
                  })
                }
            },
            cp: {
              get() {
                return this.user.cp
              },
              set(value) {
                this.setUserProperty({
                  name: 'cp',
                  value: value
                })
              }
            },
            portablePhoneNumber: {
                get() {
                    return this.user.portablePhoneNumber
                },
                set(value) {
                  this.setUserProperty({
                    name: 'portablePhoneNumber',
                    value: value
                  })
                }
            },
            portableProfesionnalPhoneNumber: {
                get() {
                    return this.user.portableProfesionnalPhoneNumber
                },
                set(value) {
                  this.setUserProperty({
                    name: 'portableProfesionnalPhoneNumber',
                    value: value
                  })
                }
            },
            homePhoneNumber: {
                get() {
                    return this.user.homePhoneNumber
                },
                set(value) {
                  this.setUserProperty({
                    name: 'homePhoneNumber',
                    value: value
                  })
                }
            },
            homeProfesionnalPhoneNumber: {
                get() {
                    return this.user.homeProfesionnalPhoneNumber
                },
                set(value) {
                  this.setUserProperty({
                    name: 'homeProfesionnalPhoneNumber',
                    value: value
                  })
                }
            },
            personnalEmail: {
                get() {
                    return this.user.personalEmail
                },
                set(value) {
                  this.setUserProperty({
                    name: 'personnalEmail',
                    value: value
                  })
                }
            },
            profesionnalEmail: {
                get() {
                    return this.user.profesionnalEmail
                },
                set(value) {
                  this.setUserProperty({
                    name: 'profesionnalEmail',
                    value: value
                  })
                }
            },
            manager: {
                get() {
                    return this.user.manager
                },
                set(value) {
                  this.setUserProperty({
                    name: 'manager',
                    value: value
                  })
                }
            },
            roleId: {
                get() {
                    return this.user.roleId
                },
                set(value) {
                    this.setUserProperty({
                        name: 'roleId',
                        value: value
                    })
                }
            },
            roles: {
                get() {
                    return this.roleList
                }, set() {}
            },
            structuress() {
                return this.structures
            },
            intervenantType: {
                get() {
                    return this.user.intervenantType
                },
                set(value) {
                    this.setUserProperty({
                        name: "intervenantType",
                        value: value
                    });
                }
            },
            status: {
                get() {
                    return this.user.status
                },
                set(value) {
                    this.setUserProperty({
                        name: "status",
                        value: value
                    });
                }
            },
            userAgencies() {
                return this.user.agencies;
            },
            isEdit() {
                return (this.$route.params.id !== undefined && this.$route.params.id !== null)
            },
            roleListSelect() {
                let roleList = []
                this.roles.forEach(item => roleList.push({value: item.id, text: item.realLabel}));
                return roleList
            },
            structureListSelect() {
              let structureList = []
              this.structuress.forEach(item => structureList.push({value: item.id, text: item.name}))
              return structureList
            },
            agencyList() {
              if(!this.agencies || this.agencies.length === 0 || !this.user.agenciesId){
                return [];
              }
              return this.agencies.reduce((list, agency) => {
                if (this.user.agenciesId.includes(agency.id)) {
                  return list;
                }
                return [...list, {
                  value: agency.id,
                  label: agency.companyName,
                }];
              }, []);
            },
        },
        mounted: async function () {
            this.setInitUser();
            if (this.isEdit) {
                this.getUser({id: this.$route.params.id})
                .catch((err) => {
                    if (err) {
                        this.$notify.error("Impossible d'accéder à cet utilisateur")
                        this.$router.push({name: 'user-list'});
                    }
                })
            }
            this.getRoleList();
            this.getStructureList({limit: 0});
            this.getAgencyList({limit: 0});
        },
        methods: {
            ...mapMutations('User', ['setUserProperty', 'setInitUser', 'getRoleList']),
            ...mapActions('User', ['postUser','putUser', 'getUser', 'getRoleList', 'editUserPassword','sendCreatePasswordLinkOnCreate']),
            ...mapActions('Structure', ['getStructureList']),
            ...mapActions('Agency', ['getAgencyList']),
            async submit() {
              // Check if form is invalid, and if so, display error
              if (this.$v.$invalid) {
                this.$v.$touch();
              } else {
                // Check if there are no previous errors in the form
                if (this.error === null) {
                  const isValidEmail = /^\w+(-?\w+)*@\w+(-?\w+)*(\.\w{2,3})+$/.test(this.email) || /^\w+(-?\w+)*(\.\w+(-?\w+))*@\w+(-?\w+)*(\.\w{2,3})+$/.test(this.email)
                  // If email is not valid, display error message
                  if (!isValidEmail) {
                    this.$notify.error("Le format de l'email est incorrect");
                    return;
                  }

                  let today = new Date();
                  let birthday = new Date(this.birthDate)
                  const ty = today.getFullYear();
                  const by = birthday.getFullYear();

                  // Check that birth date is not after today's date
                  if(birthday.getTime() > today.getTime()){
                    this.$notify.error("La date de naissance ne peut être supérieur à la date du jour");
                    return;
                  }

                  // Check that age is less than 150 years
                  if(ty - by >= 150){
                    this.$notify.error("L'âge doit être inférieur à 150 ans");
                    return;
                  }

                  // Since all form inputs are valid, proceed with creating/editing user
                  if (birthday.getTime() <= today.getTime() && ty - by < 150) {
                    if (this.isEdit) {
                      // If editing existing user, update user data and redirect to user list
                      await this.putUser()
                      this.setInitUser();
                      this.$notify.success("L'utilisateur a été modifié");
                      this.$router.push({name: 'user-list'});
                    } else {
                      // If creating new user, send email and redirect to user list
                      try {
                        await this.postUser()
                      }catch (e){
                        this.$notify.error('Un utilisateur avec cette email existe déja');
                        return
                      }
                      await this.sendCreatePasswordLinkOnCreate({email: this.email})
                      this.setInitUser();
                      this.$notify.success("L'utilisateur a été créé et un mail a été envoyé");
                      this.$router.push({name: 'user-list'});
                    }
                  }

                } else {
                  // If there are errors in the form, display error message
                  this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
                }
              }
            },

            cancel() {
                this.setInitUser();
                return this.$router.push({name: 'user-list'})
            },
            editPassword() {
                this.isEditingPassword = true
            },
            cancelEditPassword() {
                this.isEditingPassword = false
            },
            clearPasswordError() {
                this.passwordError.passwordEmpty = false
            },
            clearConfirmPasswordError() {
              this.passwordError.confirmPasswordError = false
              this.passwordError.confirmPasswordEmpty = false
            },
            submitPassword() {
                if (this.password === '') {
                    this.passwordError.passwordEmpty = true
                }
                if (this.confirmPassword === '') {
                    this.passwordError.confirmPasswordEmpty = true
                }
                if (this.password !== this.confirmPassword) {
                    this.passwordError.confirmPasswordError = true
                }
                if (this.passwordError.passwordEmpty || this.passwordError.confirmPasswordEmpty || this.passwordError.confirmPasswordError) {
                    return
                }

                this.editUserPassword({password: this.password}).then(
                    this.isEditingPassword = false,
                    this.password = '',
                    this.confirmPassword = '',
                    this.$notify.success("Le mot de passe a été modifié")
                )
            },
            showAssignAgency() {
              this.getAgencyList(10).then(() => {
                this.$bvModal.show('assign_agency');
                this.isChecked = false;
              })
            },
            submitAssignAgency() {
              this.isSubmitAssign = true;
              if (!this.newAgencyId) {
                return;
              }
              let ids = [];
              this.selectedAgencies.forEach((newAgency) => {
                ids.push(newAgency.id);
              })
              this.setUserProperty({name: "agenciesId", value: [...this.user.agenciesId, ...ids]});
              if (this.selectedAgencies) {
                this.setUserProperty({name: "agencies", value: [...this.user.agencies, ...this.selectedAgencies]});
              }
              this.newAgencyId = [];
              this.selectedAgencies = [];
              this.isSubmitAssign = false;
              this.$bvModal.hide('assign_agency');
            },
            removeAgency(agency) {
              this.setUserProperty({name: "agenciesId", value: this.user.agenciesId.filter((id) => id !== agency.id)});
              this.setUserProperty({name: "agencies", value: this.user.agencies.filter((a) => a.id !== agency.id)});
            },
            onInputUser(query) {
              this.getAgencyList({filters: ['{"column" : "company_name", "val" : "' + query + '"}']});
            },
            onSelect(items) {
              items.forEach((id) => {
                if (!this.selectedAgencies.find((item) => item.id === id)) {
                  let agency = this.agencies.find((agency) => agency.id === id);
                  this.selectedAgencies.push(agency);
                }
              })
              this.selectedAgencies = this.selectedAgencies.filter(function (value) {
                return items.find((id) => id === value.id);
              });
            },
            cancelAssignAgency() {
              this.newAgencyId = [];
              this.selectedAgencies = [];
              this.isSubmitAssign = false;
              this.$bvModal.hide('assign_agency');
            },
            updateAgencyList(){
              if (!this.isChecked){
                this.newAgencyId = [];
                return this.selectedAgencies = [];
              }
              this.getAgencyList({limit: 0}).then(() => {
               this.agencies.forEach(agency => {
                if (!this.user.agenciesId.includes(agency.id) && !this.newAgencyId.includes(agency.id)) {
                  this.selectedAgencies.push(agency);
                  this.newAgencyId.push(agency.id);
                }
               });
              })
            }
        }
    };
</script>
<template>
  <div hidden="hidden">
    <auth-wrap></auth-wrap>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "LoginPage",
  mounted: function ()
  {
    this.getUserConnected().then( () => {
      if (this.connectedUser) {
        if (localStorage.getItem('redirection')) {
          if (localStorage.getItem('redirection-id')) {
            this.$router.push({ name: localStorage.getItem('redirection'), params: { id: localStorage.getItem('redirection-id') } });
            localStorage.removeItem('redirection-id')
          } else {
            this.$router.push({name: localStorage.getItem('redirection')});
          }
        } else {
          this.$router.push({name: 'client-list'});
        }
      }
    }).catch( () => {
      if (this.$route.path.includes('evaluation')) {
        window.location.href = "/new/login/evaluation";
      } else {
        window.location.href = "/new/login";
      }
    })
  },
  computed: {
    ...mapState('User', ['connectedUser']),
    ...mapState("Cgu", ['termsAccepted']),
  },
  watch: {
    termsAccepted() {
      if (this.termsAccepted) {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        if (localStorage.getItem('redirection') === "accueil") {
          if (permissionsArray.includes('CAN_SEARCH_CLIENT')) {
            this.$router.push({name: 'accueil'});
          } else if (permissionsArray.includes('CAN_BACK_OFFICE')) {
            this.$router.push({name: 'client-list'});
          }
        } else {
          if (permissionsArray.includes('CAN_BACK_OFFICE')) {
            this.$router.push({name: 'client-list'});
          } else if (permissionsArray.includes('CAN_SEARCH_CLIENT')) {
            this.$router.push({name: 'accueil'});
          }
        }

        if (!permissionsArray.includes('CAN_BACK_OFFICE') && !permissionsArray.includes('CAN_SEARCH_CLIENT')) {
          this.$router.push({name: 'accueil'});
        }
      }
    }
  },
  methods: {
    ...mapActions('User', ['getUserConnected']),
  }
};
</script>
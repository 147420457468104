<template>
  <div class="wrapper wrapper--bo">
    <header class="bo--header">
      <div class="container-fluid">
        <div class="navbar">
          <img class="logo" src="@/assets/logo.png" alt />
          <img class="avatar" src="@/assets/avatar.png" alt />
          <a v-if="clients.length > 0 || users.length > 0" class="ml-3 mt-1 cursor-pointer" @click="showContractAlert"><i class="el-icon-warning text-danger h3"></i></a>
          <template>
            <el-select
                    v-model="structureId"
                    class="ml-4"
                    filterable
                    remote
                    :placeholder="globalStructure ? globalStructure.name : 'Sélectionnez une structure'"
                    @change="filterGlobalFromStructure">
              <el-option
                      label="Toutes"
                      :value="undefined"
              ></el-option>
              <el-option
                      selected
                      v-for="item in structureList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
              ></el-option>
            </el-select>
          </template>
          <a class="position-absolute evaluation" href="#" @click.prevent="$router.push({name: 'accueil'})">Accéder à l'évaluation</a>
          <a v-if="canAccessItem()" class="position-absolute deconexion" href="#" @click.prevent="logoutBo"> <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/> Déconnexion</a>
        </div>
      </div>
    </header>
    <main class="bo--main">
      <slot />
    </main>
    <Footer></Footer>
    <b-modal id="contract_alert" title="Contract Alert" :centered="true" size="lg">
        <template v-slot:modal-title>
            Liste des clients ayant une fin de prise en charge dans moins de 1 mois
        </template>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <GridLight  v-show="clients.length > 0"
                                label="Clients"
                                :columns="clientColumns"
                                :dataList="clients"
                                :viewTable="true"
                                @cliked="gotToClient"
                                :loading="loading"/>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="hideContractAlert">Annuler</b-button>
        </template>
    </b-modal>
  </div>
</template>
<script>
  import GridLight from "@/components/Grid/GridLight";
  import Footer from '@/components/Footer';
  import moment from 'moment';
  import { mapActions, mapState } from "vuex";
  export default {
    name: "BoWrap",
    components: {
        GridLight,
        Footer
    },
    data: () => ({
      loading: false,
      structureId: null,
      clientColumns: [
          {name: "Nom", value: 'lastname', clickable: true},
          {name: "Prénom", value: 'firstname', clickable: true},
          {name: "Ville", value: 'city'},
          {name: "Code Postal", value: 'postalCode'},
          {name: "Téléphone", value: 'portablePhoneNumber'},
          {name: "Date de fin", value: 'endDate'},
      ],
      userColumns: [
          {name: "Agence", value: 'companyName'},
          {name: "Email", value: 'email', clickable: true},
          {name: "Nom", value: 'lastName'},
          {name: "Prénom", value: 'firstName'},
          {name: "Date de fin", value: 'endDate'},
      ],
    }),
    mounted: function() {
      this.getGlobalStructureFromSession();
      this.getFilterStructureList();
      //this.getUserConnected();
    },
    computed: {
      ...mapState("Notification", ["notificationContractUser", "notificationSupportClient"]),
      ...mapState('Structure', ['globalStructure']),
      ...mapState('Filter', ['structures']),
      ...mapState("Contract", ["contracts"]),
      clients() {
          return this.notificationSupportClient.map((item) => {
              return {
                  ...item,
                  endDate: item.clientSupportList[0] ? moment(item.clientSupportList[0].dateEnd).format("DD/MM/YYYY") : null
              }
          });
      },
      users() {
          return this.notificationContractUser.map((item) => {
              return {
                  ...item,
                  endDate: moment(item.endedAt).format("DD/MM/YYYY")
              }
          });
      },
      structureList() {
        return this.structures
      }
    },
    methods:{
      ...mapActions("User", ["logout", "logoutPSC", "getUserConnected"]),
      ...mapActions("Notification", ["getNotification"]),
      ...mapActions('Structure', ['setGlobalStructure', 'getGlobalStructureFromSession', 'resetGlobalStructure']),
      ...mapActions("Contract", ["getContractList"]),
      ...mapActions("Filter", ["getFilterStructureList"]),
      logoutBo(){
        this.loading = true;
        this.logoutPSC();
        this.logout().then((res) => {
          this.loading = false;
          window.location.href = "/new/login";
          return res;
        }).catch((err) => {
          this.loading = false;
          window.location.href = "/new/login";
          throw err;
        });
      },
      canAccessItem() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return permissionsArray.includes('CAN_AUTH_LOGOUT');
      },
      async filterGlobalFromStructure() {
          if (this.structureId === undefined) {
              this.resetGlobalStructure()

          } else {
              this.setGlobalStructure({ id: this.structureId })
          }
      },
      showContractAlert() {
        this.$bvModal.show('contract_alert');
      },
      hideContractAlert() {
          this.$bvModal.hide('contract_alert');
      },
      gotToClient(client){
          this.hideContractAlert();
          this.$router.push({name: 'agency-list'}).then(() => {
              this.$router.push({name: 'client-form', params: {id: client.id}});
          });
      },
      goToUser(user){
          this.hideContractAlert();
          this.$router.push({name: 'agency-list'}).then(() => {
              this.$router.push({name: 'user-form', params: {id: user.id}});
          });
      }
    },
    created() {
      this.getNotification();
    }
  };
</script>
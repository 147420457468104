import gql from 'graphql-tag'

export const SEND_CREATE_PASSWORD_LINK = gql`
    mutation sendCreatePasswordLink(
          $email: String!
    ) {
        sendCreatePasswordLink(
            email: $email
        )
    }
`